<template>
  <div class="mx-auto contentsWidth mt-4">
    <div class="">
      <p class="title mb-0">{{year}}年度 {{name}} - 申請</p>
      <hr class="title">
    </div>


    <b-tabs
      class="gas-tab"
      nav-wrapper-class="gas-tab-wrap">
      <b-tab>
        <template v-slot:title>
          <span>申請者情報</span>
          <img
            v-if="hasUserErr"
            class="errorMark"
            src="@/assets/svg/error.svg">
        </template>
        <v-app-userinfo
          :writable="false"
          :close_datetime="close_datetime"/>
      </b-tab>
      <b-tab
        v-for="(page, index) in pages" :key="index">
        <template v-slot:title>
          <span>{{page.name}}</span>
          <img
            v-if="hasPageErr[index]"
            class="errorMark"
            src="@/assets/svg/error.svg">
        </template>
        <v-app-form
          :writable="false"
          :pageIndex="index"
          :assets="assets"
          :downloadFormFile="page.name === '追加資料' ? downloadFormAttFile: downloadFormFile"/>
      </b-tab>
    </b-tabs>

    <div class="bottonArea mt-3 mb-3 flex flexCenter">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">
        前の申請
      </b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/evaluation/list">
        一覧に戻る
      </b-button>
      <b-button
        @click="exportPdf(1)"
        variant="info"
        class="btn btn-primary btn-lg mr-4 bold">PDF出力</b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg mr-4 bold">
        次の申請
      </b-button>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';
import api from '@/modules/api';
import download from '@/modules/download';
import AppForm from '@/components/gas/parts/Form.vue';
import Userinfo from '@/components/parts/application/Userinfo.vue';

export default {
  name: 'cmsEvaluationViewFailure',
  components: {
    'v-app-form': AppForm,
    'v-app-userinfo': Userinfo,
  },
  data() {
    return {
      base: {},
      applicationId: null,
      applicationTypeId: null,
      stageId: null,
      year: null,
      name: '',
      status: null,
      hasUserErr: false,
      hasPageErr: [],
      assets: {},
      close_datetime: null,
      attachment_id: null,
    };
  },
  computed: {
    apptypeId() {
      return this.$store.state.gasApplicationForm.application_type_id;
    },
    appId() {
      return this.$store.state.gasApplicationForm.application_id;
    },
    pages() {
      return this.$store.state.gasApplicationForm.pages;
    },
    userInfo() {
      return this.$store.state.userInfo.inputData;
    },
    filterdIdsList() {
      return this.$store.state.cmsEvaluationSearch.filterdIdsList;
    },
    filterdIdsListIndex() {
      return this.filterdIdsList.findIndex((data) => {
        const findAppId = data.appId === Number(this.appId);
        return findAppId;
      });
    },
    hasNextApp() {
      if (this.filterdIdsList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdsListIndex + 1;
      return newIndex < this.filterdIdsList.length;
    },
    hasPrevApp() {
      if (this.filterdIdsList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdsListIndex - 1;
      return newIndex >= 0;
    },
  },
  methods: {
    async fetchData(appId) {
      const param = {
        appId,
      };
      const response = await api.send('/api/cms/evaluation/view/failure/app', param)
        .catch((err) => {
          console.error(err);
        });

      if (!response) {
        await this.alert('申請のデータを取得できませんでした。');
        this.$router.push({ path: 'cms/evaluation/list' });
        return;
      }
      const { base, attachmentId } = response.data;
      this.base = base;
      this.year = base.year;
      this.name = base.name;
      this.status = base.status;
      this.applicationTypeId = base.application_type_id;
      this.close_datetime = base.close_datetime;
      this.assets = response.data.assets;
      this.attachment_id = attachmentId;
      this.$store.commit('cmsApplicationControl/updateStatus', base.status);
      this.$store.commit('cmsApplicationControl/updateReason', base.reason);
      const {
        pages,
        userInfo,
        answers,
        applicationId,
        userId,
      } = response.data;
      this.$store.commit('gasApplicationForm/setDbData', pages);
      this.$store.commit('gasApplicationForm/setUserId', userId);
      this.$store.commit('gasApplicationForm/setApplicationId', Number(applicationId));
      this.$store.commit('gasApplicationForm/setApplicationTypeId', base.application_type_id);
      this.$store.commit('gasApplicationInput/setDbData', { pages, answers });
      this.$store.commit('userInfo/setDbData', userInfo);
    },
    async downloadFormAttFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$store.state.gasApplicationForm.application_id,
        attachment_id: this.attachment_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/attachments/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async downloadFormFile(fieldId, optionId, fileName) {
      const param = {
        application_id: this.$store.state.gasApplicationForm.application_id,
        field_id: fieldId,
        option_id: optionId,
        answer_no: 1, // ファイルは当面１つ固定
      };
      param.user_id = this.$store.state.gasApplicationForm.user_id;
      const url = '/api/cms/applications/download/uploadedFile';

      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    async nextApp() {
      const newIndex = this.filterdIdsListIndex + 1;
      const data = this.filterdIdsList[newIndex];
      this.$router.push({ path: `/cms/evaluation/view/failure/${data.appId}/${data.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData(data.appId);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdsListIndex - 1;
      const data = this.filterdIdsList[newIndex];
      this.$router.push({ path: `/cms/evaluation/view/failure/${data.appId}/${data.stageId}` });
      this.$store.dispatch('page/onLoading');
      await this.fetchData(data.appId);
      this.$store.dispatch('page/offLoading');
    },
    // 実績のPDF出力にも使えるように引数で渡す
    // type 1=>申請，2=>報告
    async exportPdf(kind) {
      this.$store.dispatch('page/onWaiting');
      const params = {
        kind,
        apptypeId: this.applicationTypeId,
        appId: this.applicationId,
        stageId: this.stageId,
      };
      const requireBlob = true;
      const response = await api.send('/api/cms/evaluation/export/pdf', params, requireBlob)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      const fileName = `${this.base.year}_${this.base.name}_${this.userInfo.sei}${this.userInfo.mei}.pdf`;
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    this.$store.commit('gasApplicationForm/init');
    this.$store.commit('gasApplicationForm/setIsCms', true);
    const { appId, stageId } = this.$route.params;
    this.applicationId = appId;
    this.stageId = stageId;
    await this.fetchData(appId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .bottonArea {
    width: 1100px;
  }
  .errorMark {
    position: absolute;
    top: -7px;
    left: 150px;
  }
  .warning {
    color: #dc3545;
  }
  .subject {
    border-bottom: .1rem solid #A0A9D0;
    margin: 3rem auto 0;
    width: 1080px;
  }

  .err-wrap>p{
    color: #dc3545;
  }
</style>
